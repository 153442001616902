import React from "react";
// import { useEffect } from "react"

export default function LikesCounter() {
    // const likesObj = useSelector((state) => state.posts.allLikes)
    // const likes = Object.values(likesObj)

    // if (likes.length) return <h1 Hi />

    // Need to integrate BE data for likes
    return (
        <div className="likes-counter">
            <i className="fa fa-heart"> 10</i>
        </div>
    )
}
