import React from 'react';
import "./Loading.css"

function LoadingScreen() {
  return (
    <div className="hourglass"></div>
  );
}

export default LoadingScreen;
